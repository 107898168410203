/*
 * Copyright Schrodinger, LLC
 *
 * @fileoverview Class to help sanitize HTML elements
 */
import environment from 'bb/util/environment';
goog.require('goog.array');
goog.require('goog.html.SafeHtml');
goog.require('goog.html.SafeUrl');
goog.require('goog.html.sanitizer.HtmlSanitizer');
goog.require('goog.html.sanitizer.HtmlSanitizer.Builder');
goog.require('goog.html.sanitizer.unsafe');
goog.require('goog.memoize');
goog.require('goog.object');
goog.require('goog.string');
goog.require('goog.string.Const');

// eslint-disable-next-line no-control-regex
const QUICK_CHECK_REGEX = /[\x00&<>"']/;

/**
 * recursively sanitizes Object or string
 *
 * @param {Object | string } values
 * @param {Object | undefined} htmlAllowList
 * @return {Object | string}
 */
export const sanitize = (values, htmlAllowList) => {
  if (_.isArray(values)) {
    return values.map((value) => sanitize(value, htmlAllowList));
  }
  if (_.isObject(values)) {
    return _.mapValues(values, (value) => sanitize(value, htmlAllowList));
  }
  if (_.isString(values)) {
    return sanitizeHelper(values, htmlAllowList);
  }
  return values;
};

/**
 * removes HTML tags not in allowlist
 *
 * @param {string} rawHTML
 * @param {Object} htmlAllowList
 * @return {string}
 * @private
 */
const sanitizeHelper = function (rawHTML, htmlAllowList) {
  if (!QUICK_CHECK_REGEX.test(rawHTML)) {
    return rawHTML;
  }
  return goog.html.SafeHtml.unwrap(getSanitizer(htmlAllowList).sanitize(rawHTML));
};

/**
 * Returns a memoized copy of the sanitizer.
 *
 * @param {Object} htmlAllowList
 * @return {!goog.html.sanitizer.HtmlSanitizer}
 * @private
 */
const getSanitizer = goog.memoize((htmlAllowList = {}) => {
  /*
   * Object must be structured as !Object.<string, string|Array.<string>>
   * Example:
   * {
   *   '*': ['class', 'style'], //Allow class and style for all attributes
   *   'a': ['href'], //Allow href for anchor tag
   *   'img': ['src'], //Allow src for image tag
   *   'span': '' //Allow span with just defaults
   * }
   */

  //Get all allowed elements, omiting wildcard
  const allowedElements = goog.object.getKeys(htmlAllowList);
  goog.array.remove(allowedElements, WILDCARD);

  const allowedAttributes = [];

  //Loop over each item in the array and add it as allowed attributes to element pair
  //Wildcard attributes are allowed for all elements
  _.each(htmlAllowList, (attributes, element) => {
    _.each(attributes, (attributeName) => {
      //Transform our wildcard into google's standard. Currently they are the same but that may change
      const tagName = element === WILDCARD ? GOOG_SANITIZER_WILDCARD : element;
      allowedAttributes.push({
        attributeName: attributeName,
        tagName: tagName,
        policy: getPolicy(attributeName),
      });
    });
  });

  /*
   * NOTE (asif) Normally some combinations like a: href and img: src are not included in this allowlist
   * https://github.com/google/closure-library/blob/master/closure/goog/html/sanitizer/attributewhitelist.js#L113
   * It allowed '* HREF' and '* SRC' but this line will throw an error:
   * https://github.com/google/closure-library/blob/master/closure/goog/html/sanitizer/htmlsanitizer.js#L528
   * So we override it to allow using goog.html.sanitizer.unsafe
   * We do this for both tags and attributes and defer to SA for sanity
   */

  //Build sanitizer
  let builder = new goog.html.sanitizer.HtmlSanitizer.Builder();

  const tagJustificaiton = goog.string.Const.from('Allow all allowlisted elements');
  builder = goog.html.sanitizer.unsafe
    .alsoAllowTags(tagJustificaiton, builder, allowedElements)
    //Allow only allowlisted elements
    .onlyAllowTags(allowedElements);

  const attributeJustification = goog.string.Const.from('Allow all allowlisted attributes');
  builder = goog.html.sanitizer.unsafe
    .alsoAllowAttributes(attributeJustification, builder, allowedAttributes)
    //Allow only allowlisted attributes for specific attributes
    .onlyAllowAttributes(allowedAttributes);

  return builder.build();
});

/**
 * Get the policy for the given attribute.
 *
 * @param {string} attributeName
 * @return {?function()}
 * @private
 */
const getPolicy = goog.memoize((attributeName) => {
  if (_.includes(attributesThatCanCauseNetworkRequests, attributeName)) {
    return networkRequestUrlPolicy;
  }

  // no policy needed
  return undefined;
});

/**
 * Policy that sanitizes given Url.
 *
 * @param {string} url
 * @return {(string|null)}
 * @private
 */
const networkRequestUrlPolicy = function (url) {
  const wrappedSafeUrl = goog.html.SafeUrl.sanitize(_.trim(url));
  if (!wrappedSafeUrl) {
    return null;
  }

  const safeUrl = goog.html.SafeUrl.unwrap(wrappedSafeUrl);
  if (safeUrl !== goog.html.SafeUrl.INNOCUOUS_STRING) {
    return safeUrl;
  }
  return null;
};

/**
 * NOTE (pradeep): We have an explicit map of allowed attributes, and using said map will clear default policies.
 * Unless the default policies are added back again, there's a risk of XSS through the allowed attributes themselves.
 *
 * Hence we hand pick potentially dangerous attributes and set a default policy.
 * (see - https://github.com/google/closure-library/blob/master/closure/goog/html/sanitizer/attributelist.js#L128)
 *
 * @type {!Array<!string>}
 * @const
 * @private
 */
const attributesThatCanCauseNetworkRequests = ['href', 'src', 'longdesc'];

/**
 * Wildcard character
 *
 * @const
 */
const WILDCARD = '*';

/**
 * Wildcard character for goog.html.sanitizer
 *
 * @const
 */
const GOOG_SANITIZER_WILDCARD = '*';
